import React, { useEffect, useState } from 'react';
import ReactTable from '../common/UI/ReactTable';
import services from '../api/api';
import { Button } from 'react-bootstrap';
import CustomDropdown from '../common/UI/Dropdown';
import { useNavigate } from 'react-router-dom';

const referralManagement = () => {
  const navigate = useNavigate();
  
  const [referralData, setReferralData] = useState()
  const [referralUsers, setReferralUsers] = useState()
  const [referralForm, setReferralForm] = useState()
  const [referralPromotionCondition, setPromotionCondition] = useState(null)
  const [referralDiscountAmount, setReferralDiscountAmount] = useState(null)
  const [referralDiscountPercentage, setReferralDiscountPercentage] = useState(null)
  const [referralCreditAmount, setReferralCreditAmount] = useState()
  const referralOptions = [
    {
    label: 'Amount',
    value: 'Amount'
    },
    {
      label: 'Percentage',
      value: 'Percentage'
    }
  ]

  useEffect(() => {
    getReferralData();
    getReferralUsers();
  }, []);

  const getReferralData = () => {
    services.getReferralData()
    .then((res) => {
      setReferralData(res.data.data[0])
    })
  }

  const getReferralUsers = () => {
    services.getReferralUsers()
    .then((res) => {
      setReferralUsers(res.data.data)
    })
  }

  const updateReferralRecord = () => {
    const payload = {
      discount_in_amount: referralDiscountAmount,
      discount_in_percentage: referralDiscountPercentage,
      credit_amount: referralCreditAmount 
    }

    services.updateReferralData(referralData._id, payload)
    .then((res) => {
      navigate(0)
    })
  }

  const columns = [
    {
      Header: 'Coupon Code',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>{row.original.referralCode}</span>;
      },
    },
    {
      Header: 'User',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>{row.original?.referrer_user?.email} </span>;
      },
    },
    {
      Header: 'Code Used',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>{row.original?.orderdList?.length} </span>;
      },
    },
  ]
  
  return (
    <div className="content_wrapper all_products_main">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="box_main">
              
              {referralForm ? 
                (
                  <>
                    <div className="box_main">
                    <Button
                      type="button"
                      variant="sky"
                      className="my-2 me-2"
                      onClick={() => setReferralForm(false)}
                    >
                      Back
                    </Button>
                    <div
                      className="header_top"
                      style={{ justifyContent: 'space-between' }}
                    >
                      <h3>Edit Referral Promotion</h3>
                    </div>
                    <div>
                      <div className="col-xl-4 col-md-6 col-lg-6 form-group mt-3">
                        <CustomDropdown
                          height="44px"
                          className="mx-2"
                          onChange={(value) => {
                            setPromotionCondition(value);
                          }}
                          border={'1 px solid #9aa5ad'}
                          options={referralOptions}
                        />
                      </div>
                      {referralPromotionCondition?.value === 'Amount' &&
                        <div className="col-xl-4 col-md-6 col-lg-6 form-group mt-3">
                          <h6 className="available-text">
                            Referral Promotion Amount:{' '}
                          </h6>
                          <input
                            placeholder="Enter Amount"
                            className="form-control me-2  border-1 shadow-none my-2"
                            value={referralDiscountAmount}
                            onChange={(e) => setReferralDiscountAmount(e.target.value)}
                          />
                        </div>
                      }
                      {referralPromotionCondition?.value === 'Percentage' &&
                        <div className="col-xl-4 col-md-6 col-lg-6 form-group mt-3">
                          <h6 className="available-text">
                            Referral Promotion Percentage:{' '}
                          </h6>
                          <input
                            placeholder="Enter Percentage"
                            className="form-control me-2  border-1 shadow-none my-2"
                            value={referralDiscountPercentage}
                            onChange={(e) => setReferralDiscountPercentage(e.target.value)}
                          />
                        </div>
                      }
                      <div className="col-xl-4 col-md-6 col-lg-6 form-group mt-3">
                        <h6 className="available-text">
                          Referral Credit Amount:
                        </h6>
                        <input
                          placeholder="Enter Credit Amount"
                          className="form-control me-2  border-1 shadow-none my-2"
                          value={referralCreditAmount}
                          onChange={(e) => setReferralCreditAmount(e.target.value)}
                        />
                      </div>
                      <Button onClick={() => updateReferralRecord()}>
                        Submit
                      </Button>
                    </div>
                  </div>
                  </>
                )
                : 
                (
                  <>
                    <div className="header_top">
                      <h3>Referral Management</h3>
                    </div>
                    <div className=''>
                      <table className="table table-borderless mb-3">
                        <tr>
                          <td><strong>Referral Discount</strong></td>
                          <td><strong>Credit Amount</strong></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>{referralData?.discount_in_amount ? `£${referralData?.discount_in_amount}`: `${referralData?.discount_in_percentage}%`}</td>
                          <td>${referralData?.credit_amount}</td>
                          <td>
                            <Button
                              className="btn btn-primary px-2 py-1 mx-1 my-2"
                              onClick={() => setReferralForm(true)}
                            >
                              Edit
                            </Button>
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div className="header_top">
                      <h3>Referral Users</h3>
                    </div>
                    <ReactTable
                      title="Referral Code Users"
                      tableColumns={columns}
                      tableData={referralUsers || []}
                      seeAll={true}
                    />
                  </>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default referralManagement;
