import {
  faArrowRight,
  faMinusCircle,
  faPlusCircle,
  faListUl,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-bootstrap';
import ReactTextareaAutosize from 'react-textarea-autosize';
import minusImg from '../../../assets/images/minus-icon.svg';
import CustomDropdown from '../../../common/UI/Dropdown';
import {
  booleanOptions,
  booleanRestrictAndCertainOptions,
  booleanRestrictOptions,
  getItemStyle,
  getListStyleSelected,
  inputBooleanQuestionType,
  questionType,
} from '../../../utils/productHelper';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TextEditor from '../../../common/TextEditor';
import api from '../../../api/api';
import { toastError, toastSuccess } from '../../../common/UI/Toasts';

const SpecificQuestions = (props) => {
  const {
    questionsFor,
    questionsData,
    handleAddSubQuestion,
    handleAddSubSubQuestion,
    handleChange,
    handleDeleteMainQuestion,
    handleAddMainQuestion,
    handleChangeForSubQuestion,
    handleChangeForSubSubQuestion,
    handleDeleteSubQuestion,
    onDragEndQuestion,
    handleSelectQuestionModalToggle,
    handlePrevious,
    handleSubmit,
    //
    toggleShowOptionsModal,
    toggleShowSubOptionsModal,
    toggleShowSubSubOptionsModal,
    setSelectedQuestionsIndex,
    setSelectedSubQuestionsIndex,
    setSelectedSubSubQuestionsIndex
  } = props && props;

  return (
    <div className="row mt-5 all_products_main">
      <div
        className="col-md-12 d-flex"
        style={{ justifyContent: 'space-between' }}
      >
        <h4 className="middle_title">Product Specific Questions</h4>
        <div>
          <Button className="btn-primary" onClick={handleAddMainQuestion}>
            + Add
          </Button>{' '}
        </div>
      </div>
      <div className="col-md-12">
        <div className="">
          <div className="row">
            <div className="col-md-7">Questions</div>
            <div className="col-md-2">Answer Type</div>
            <div className="col-md-2">Pref. Ans.</div>
            <div className="col-md-1"></div>
          </div>
          <DragDropContext onDragEnd={onDragEndQuestion} className="w-75">
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyleSelected(snapshot.isDraggingOver)}
                >
                  {questionsData?.map((el, index) => {
                    return (
                      <Draggable
                        key={`main${index}`}
                        draggableId={`main${index}`}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            className="question_wrapper"
                            ref={provided.innerRef}
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}
                            style={{
                              ...getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style,
                                el?._id,
                              ),
                            }}
                          >
                            <div
                              style={{
                                pointerEvents: `${el?._id ? 'none' : 'auto'}`,
                              }}
                              key={`main${index}`}
                              className="row m-1"
                            >
                              {/* check box for html */}
                              <div
                                className="col-md-1 d-flex align-items-center justify-content-center"
                                style={{ width: '1px' }}
                              >
                                <input
                                  type="checkbox"
                                  name="isHTML"
                                  onChange={(e) =>
                                    !el?.isHTML &&
                                    handleChange(
                                      e.target.name,
                                      e.target.checked,
                                      index,
                                    )
                                  }
                                  checked={el?.isHTML}
                                />
                              </div>
                              {/* text editor/text area */}
                              <div
                                className={`${
                                  (props.subcategoryId &&
                                    el.preferredAnswer?.value ===
                                      'yesCertainRestrict') ||
                                  el.preferredAnswer?.value ===
                                    'noCertainRestrict'
                                    ? 'col-md-4'
                                    : 'col-md-6'
                                }`}
                              >
                                {el?.isHTML ? (
                                  <TextEditor
                                    name="question"
                                    value={el?.question}
                                    onEditorChange={(value) => {
                                      handleChange('question', value, index);
                                    }}
                                    editorHeight="30vh"
                                    style={{
                                      pointerEvents: 'auto',
                                      width: '100%',
                                    }}
                                  />
                                ) : (
                                  <ReactTextareaAutosize
                                    name="question"
                                    type="text"
                                    className="form-control form_input"
                                    placeholder=""
                                    onChange={(e) =>
                                      handleChange(
                                        e.target.name,
                                        e.target.value,
                                        index,
                                      )
                                    }
                                    value={el?.question}
                                    minRows={1}
                                    style={{
                                      borderRadius: '10px',
                                      pointerEvents: 'auto',
                                    }}
                                  />
                                )}
                              </div>
                              {/* question type */}
                              <div className={`col-md-2`}>
                                <CustomDropdown
                                  name="questionType"
                                  height="44px"
                                  className="question_dropdown"
                                  onChange={(value) => {
                                    handleChange('questionType', value, index);
                                  }}
                                  value={el?.questionType}
                                  options={questionType}
                                />
                              </div>
                              {/* preferred answer */}
                              <div className={`col-md-2`}>
                                <CustomDropdown
                                  name="preferredAnswer"
                                  height="44px"
                                  onChange={(value) =>
                                    handleChange(
                                      'preferredAnswer',
                                      value,
                                      index,
                                    )
                                  }
                                  isDisabled={el?.isAnswerDisabled}
                                  className="question_dropdown"
                                  value={el?.preferredAnswer}
                                  options={
                                    props.subcategoryId
                                      ? booleanRestrictAndCertainOptions
                                      : booleanRestrictOptions
                                  }
                                />
                              </div>
                              {/* restricted meds */}
                              {(props.subcategoryId &&
                                el.preferredAnswer?.value ===
                                  'yesCertainRestrict') ||
                              el.preferredAnswer?.value ===
                                'noCertainRestrict' ? (
                                <div
                                  className="col-md-2"
                                  style={{ pointerEvents: 'auto' }}
                                >
                                  <CustomDropdown
                                    name="restrictedMeds"
                                    onChange={(value, action) => {
                                      if (
                                        el._id &&
                                        action.action === 'remove-value'
                                      ) {
                                        api
                                          .removeRestrictedMeds({
                                            product_id:
                                              action.removedValue?.value,
                                            question_id: el._id,
                                          })
                                          .then((res) => {
                                            toastSuccess(
                                              'Med removed from restriction!',
                                            );
                                            handleChange(
                                              'restrictedMeds',
                                              value,
                                              index,
                                            );
                                          })
                                          .catch((err) => {
                                            toastError(
                                              'Cannot delete this med from restriction!',
                                            );
                                          });

                                        // when its question's edit and a value is removed from restricted med, check whether there
                                        // are any users already restricted for this med
                                      } else {
                                        handleChange(
                                          'restrictedMeds',
                                          value,
                                          index,
                                        );
                                      }
                                    }}
                                    isMulti={true}
                                    className="question_dropdown"
                                    value={el?.restrictedMeds}
                                    options={props.subCatProducts}
                                  />
                                </div>
                              ) : null}

                              <div className="col-md-1 d-flex align-items-center">
                                {/* Minus icon to delete */}
                                <FontAwesomeIcon
                                  className="pt-1 d-block m-2"
                                  style={{ pointerEvents: 'auto' }}
                                  onClick={() =>
                                    handleDeleteMainQuestion(index)
                                  }
                                  icon={faMinusCircle}
                                  size="lg"
                                  color={'#088fff'}
                                  // className="m-4"
                                />
                                {/* Plus icon to add subquestions */}
                                <span
                                  className="m-2 pt-1 d-block"
                                  style={{
                                    width: '50px',
                                    cursor: `${
                                      el?.questionType?.value === 'boolean' ||
                                      el?.questionType?.value === 'select'
                                        ? 'pointer'
                                        : 'not-allowed'
                                    }`,
                                  }}
                                  onClick={() => {
                                    if (
                                      el?.questionType?.value === 'boolean' ||
                                      el?.questionType?.value === 'select'
                                    )
                                      handleAddSubQuestion(index);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faPlusCircle}
                                    size="lg"
                                    color={'#088fff'}
                                  />
                                </span>
                                {/* select options icon */}
                                {el?.questionType?.value === 'select' ? (
                                  <span
                                    className="m-2 pt-1 d-block"
                                    style={{
                                      width: '50px',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                      toggleShowOptionsModal();
                                      setSelectedQuestionsIndex(index);
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      style={{ pointerEvents: 'auto' }}
                                      icon={faListUl}
                                      size="lg"
                                      color={'#088fff'}
                                    />
                                  </span>
                                ) : null}
                              </div>
                            </div>
                            {el?.subQuestions?.length
                              ? el?.subQuestions.map((elem, subIndex) => {
                                  const labelValuePairOptions = el?.options
                                    ? el.options.map((el) => {
                                        return { label: el, value: el };
                                      })
                                    : [];
                                  return (
                                    <>
                                      <div
                                        key={`sub${subIndex}`}
                                        className="row sub_question"
                                        style={{
                                          pointerEvents: `${
                                            el?._id ? 'none' : 'auto'
                                          }`,
                                        }}
                                      >
                                        <div
                                          className="col-md-1 d-flex align-items-center justify-content-center"
                                          style={{ width: '1px' }}
                                        >
                                          <input
                                            type="checkbox"
                                            name="isHTML"
                                            onChange={(e) =>
                                              !elem?.isHTML &&
                                              handleChangeForSubQuestion(
                                                e.target.name,
                                                e.target.checked,
                                                subIndex,
                                                index,
                                              )
                                            }
                                            checked={elem?.isHTML}
                                          />
                                        </div>
                                        <div className="col-md-2 d-flex align-items-center">
                                          {/* <FontAwesomeIcon icon={faArrowRight} /> */}
                                          <div className="d-flex align-items-center">
                                            <span className="m-2">If </span>
                                            <CustomDropdown
                                              name="questionType"
                                              height="44px"
                                              className="question_dropdown"
                                              onChange={(value) => {
                                                handleChangeForSubQuestion(
                                                  'selectedAnswer',
                                                  value,
                                                  subIndex,
                                                  index,
                                                );
                                              }}
                                              value={elem?.selectedAnswer}
                                              options={
                                                el.questionType?.value ===
                                                'boolean'
                                                  ? booleanOptions
                                                  : el.questionType?.value ===
                                                    'select'
                                                  ? labelValuePairOptions
                                                  : null
                                              }
                                              controlStyles={{
                                                width: '127.931px',
                                                textAlign: 'center',
                                              }}
                                            />
                                            <span className="m-2">:</span>
                                          </div>
                                        </div>
                                        <div
                                          className={`${
                                            (props.subcategoryId &&
                                              el.preferredAnswer?.value ===
                                                'yesCertainRestrict') ||
                                            el.preferredAnswer?.value ===
                                              'noCertainRestrict'
                                              ? 'col-md-3'
                                              : 'col-md-4'
                                          }`}
                                        >
                                          {elem?.isHTML ? (
                                            <TextEditor
                                              name="question"
                                              value={elem?.question}
                                              onEditorChange={(value) => {
                                                handleChangeForSubQuestion(
                                                  'question',
                                                  value,
                                                  subIndex,
                                                  index,
                                                );
                                              }}
                                              editorHeight="30vh"
                                            />
                                          ) : (
                                            <ReactTextareaAutosize
                                              name="question"
                                              type="text"
                                              className="form-control form_input"
                                              placeholder=""
                                              onChange={(e) =>
                                                handleChangeForSubQuestion(
                                                  e.target.name,
                                                  e.target.value,
                                                  subIndex,
                                                  index,
                                                )
                                              }
                                              value={elem?.question}
                                              minRows={1}
                                              style={{
                                                borderRadius: '10px',
                                              }}
                                            />
                                          )}
                                        </div>
                                        <div className="col-md-2 d-flex align-items-center">
                                          <CustomDropdown
                                            name="questionType"
                                            height="44px"
                                            className="question_dropdown"
                                            onChange={(value) =>
                                              handleChangeForSubQuestion(
                                                'questionType',
                                                value,
                                                subIndex,
                                                index,
                                              )
                                            }
                                            value={elem?.questionType}
                                            options={inputBooleanQuestionType}
                                          />
                                        </div>
                                        <div className="col-md-2 d-flex align-items-center">
                                          <CustomDropdown
                                            name="preferredAnswer"
                                            height="44px"
                                            onChange={(value) =>
                                              handleChangeForSubQuestion(
                                                'preferredAnswer',
                                                value,
                                                subIndex,
                                                index,
                                              )
                                            }
                                            isDisabled={elem?.isAnswerDisabled}
                                            className="question_dropdown"
                                            value={elem?.preferredAnswer}
                                            options={
                                              props.subcategoryId
                                                ? booleanRestrictAndCertainOptions
                                                : booleanRestrictOptions
                                            }
                                          />
                                        </div>
                                        {props.subcategoryId &&
                                        (elem.preferredAnswer?.value ===
                                          'yesCertainRestrict' ||
                                          elem.preferredAnswer?.value ===
                                            'noCertainRestrict') ? (
                                          <div className="col-md-2">
                                            <CustomDropdown
                                              name="restrictedMeds"
                                              onChange={(value) =>
                                                handleChangeForSubQuestion(
                                                  'restrictedMeds',
                                                  value,
                                                  subIndex,
                                                  index,
                                                )
                                              }
                                              isMulti={true}
                                              className="question_dropdown"
                                              value={elem?.restrictedMeds}
                                              options={props.subCatProducts}
                                            />
                                          </div>
                                        ) : null}
                                        <div className="col-md-1 d-flex align-items-center">
                                          <FontAwesomeIcon
                                            className="pt-1 d-block m-2"
                                            onClick={() =>
                                              handleDeleteSubQuestion(
                                                subIndex,
                                                index,
                                              )
                                            }
                                            icon={faMinusCircle}
                                            size="lg"
                                            color={'#088fff'}
                                            // className="m-4"
                                          />
                                          {/* Plus icon to add subsubquestions */}
                                          <span
                                            className="m-2 pt-1 d-block"
                                            style={{
                                              width: '50px',
                                              cursor: `${
                                                elem?.questionType?.value === 'boolean' ||
                                                elem?.questionType?.value === 'select'
                                                  ? 'pointer'
                                                  : 'not-allowed'
                                              }`,
                                            }}
                                            onClick={() => {
                                              if (
                                                elem?.questionType?.value === 'boolean' ||
                                                elem?.questionType?.value === 'select'
                                              )
                                                handleAddSubSubQuestion(index, subIndex);
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              icon={faPlusCircle}
                                              size="lg"
                                              color={'#088fff'}
                                            />
                                          </span>
                                          {elem?.questionType?.value ===
                                          'select' ? (
                                            <span
                                              className="m-2 pt-1 d-block"
                                              style={{
                                                width: '50px',
                                                cursor: 'pointer',
                                              }}
                                              onClick={() => {
                                                toggleShowSubOptionsModal();
                                                setSelectedQuestionsIndex(index);
                                                setSelectedSubQuestionsIndex(subIndex);
                                              }}
                                            >
                                              <FontAwesomeIcon
                                                style={{ pointerEvents: 'auto' }}
                                                icon={faListUl}
                                                size="lg"
                                                color={'#088fff'}
                                              />
                                            </span>

                                          ) : null}
                                        </div>
                                      </div>
                                      {elem?.subSubQuestions?.length 
                                        ? elem?.subSubQuestions.map((element, subSubIndex) => {
                                          const labelValuePairSubOptions = elem?.options
                                            ? elem.options.map((el) => {
                                                return { label: el, value: el };
                                              })
                                              : 
                                              [];
                                              return (
                                                <div
                                                  key={`sub${subSubIndex}`}
                                                  className="row sub_sub_question"
                                                  style={{
                                                    pointerEvents: `${
                                                      el?._id ? 'none' : 'auto'
                                                    }`,
                                                  }}
                                                >
                                                  <div
                                                    className="col-md-1 d-flex align-items-center justify-content-center"
                                                    style={{ width: '1px' }}
                                                  >
                                                    <input
                                                      type="checkbox"
                                                      name="isHTML"
                                                      onChange={(e) =>
                                                        !element?.isHTML &&
                                                        handleChangeForSubSubQuestion(
                                                          e.target.name,
                                                          e.target.checked,
                                                          subSubIndex,
                                                          subIndex,
                                                          index,
                                                        )
                                                      }
                                                      checked={element?.isHTML}
                                                    />
                                                  </div>
                                                  <div className="col-md-2 d-flex align-items-center">
                                                    {/* <FontAwesomeIcon icon={faArrowRight} /> */}
                                                    <div className="d-flex align-items-center">
                                                      <span className="m-2">If </span>
                                                      <CustomDropdown
                                                        name="questionType"
                                                        height="44px"
                                                        className="question_dropdown"
                                                        onChange={(value) => {
                                                          handleChangeForSubSubQuestion(
                                                            'selectedAnswer',
                                                            value,
                                                            subSubIndex,
                                                            subIndex,
                                                            index,
                                                          );
                                                        }}
                                                        value={element?.selectedAnswer}
                                                        options={
                                                          elem.questionType?.value ===
                                                          'boolean'
                                                            ? booleanOptions
                                                            : elem.questionType?.value ===
                                                              'select'
                                                            ? labelValuePairSubOptions
                                                            : null
                                                        }
                                                        controlStyles={{
                                                          width: '127.931px',
                                                          textAlign: 'center',
                                                        }}
                                                      />
                                                      <span className="m-2">:</span>
                                                    </div>
                                                  </div>
                                                  <div
                                                    className={`${
                                                      (props.subcategoryId &&
                                                        elem.preferredAnswer?.value ===
                                                          'yesCertainRestrict') ||
                                                      elem.preferredAnswer?.value ===
                                                        'noCertainRestrict'
                                                        ? 'col-md-3'
                                                        : 'col-md-4'
                                                    }`}
                                                  >
                                                    {element?.isHTML ? (
                                                      <TextEditor
                                                        name="question"
                                                        value={element?.question}
                                                        onEditorChange={(value) => {
                                                          handleChangeForSubSubQuestion(
                                                            'question',
                                                            value,
                                                            subSubIndex,
                                                            subIndex,
                                                            index,
                                                          );
                                                        }}
                                                        editorHeight="30vh"
                                                      />
                                                    ) : (
                                                      <ReactTextareaAutosize
                                                        name="question"
                                                        type="text"
                                                        className="form-control form_input"
                                                        placeholder=""
                                                        onChange={(e) =>
                                                          handleChangeForSubSubQuestion(
                                                            e.target.name,
                                                            e.target.value,
                                                            subSubIndex,
                                                            subIndex,
                                                            index,
                                                          )
                                                        }
                                                        value={element?.question}
                                                        minRows={1}
                                                        style={{
                                                          borderRadius: '10px',
                                                        }}
                                                      />
                                                    )}
                                                  </div>
                                                  <div className="col-md-2 d-flex align-items-center">
                                                    <CustomDropdown
                                                      name="questionType"
                                                      height="44px"
                                                      className="question_dropdown"
                                                      onChange={(value) =>
                                                        handleChangeForSubSubQuestion(
                                                          'questionType',
                                                          value,
                                                          subSubIndex,
                                                          subIndex,
                                                          index,
                                                        )
                                                      }
                                                      value={element?.questionType}
                                                      options={inputBooleanQuestionType}
                                                    />
                                                  </div>
                                                  <div className="col-md-2 d-flex align-items-center">
                                                    <CustomDropdown
                                                      name="preferredAnswer"
                                                      height="44px"
                                                      onChange={(value) =>
                                                        handleChangeForSubSubQuestion(
                                                          'preferredAnswer',
                                                          value,
                                                          subSubIndex,
                                                          subIndex,
                                                          index,
                                                        )
                                                      }
                                                      isDisabled={element?.isAnswerDisabled}
                                                      className="question_dropdown"
                                                      value={element?.preferredAnswer}
                                                      options={
                                                        props.subcategoryId
                                                          ? booleanRestrictAndCertainOptions
                                                          : booleanRestrictOptions
                                                      }
                                                    />
                                                  </div>
                                                  {props.subcategoryId &&
                                                  (element.preferredAnswer?.value ===
                                                    'yesCertainRestrict' ||
                                                    element.preferredAnswer?.value ===
                                                      'noCertainRestrict') ? (
                                                    <div className="col-md-2">
                                                      <CustomDropdown
                                                        name="restrictedMeds"
                                                        onChange={(value) =>
                                                          handleChangeForSubSubQuestion(
                                                            'restrictedMeds',
                                                            value,
                                                            subSubIndex,
                                                            subIndex,
                                                            index,
                                                          )
                                                        }
                                                        isMulti={true}
                                                        className="question_dropdown"
                                                        value={element?.restrictedMeds}
                                                        options={props.subCatProducts}
                                                      />
                                                    </div>
                                                  ) : null}
                                                  <div className="col-md-1 d-flex align-items-center">
                                                    <FontAwesomeIcon
                                                      className="pt-1 d-block m-2"
                                                      onClick={() =>
                                                        handleDeleteSubQuestion(
                                                          subIndex,
                                                          index,
                                                        )
                                                      }
                                                      icon={faMinusCircle}
                                                      size="lg"
                                                      color={'#088fff'}
                                                      // className="m-4"
                                                    />
                                                    {element?.questionType?.value ===
                                                    'select' ? (
                                                      <span
                                                        className="m-2 pt-1 d-block"
                                                        style={{
                                                          width: '50px',
                                                          cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                          toggleShowSubSubOptionsModal();
                                                          setSelectedQuestionsIndex(index);
                                                          setSelectedSubQuestionsIndex(subIndex);
                                                          setSelectedSubSubQuestionsIndex(subSubIndex);
                                                        }}
                                                      >
                                                        <FontAwesomeIcon
                                                          style={{ pointerEvents: 'auto' }}
                                                          icon={faListUl}
                                                          size="lg"
                                                          color={'#088fff'}
                                                        />
                                                      </span>

                                                    ) : null}
                                                  </div>
                                                </div>
                                              );
                                            })
                                        : null
                                      }
                                    </>
                                  );
                                })
                              : null}
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          {/*  */}
        </div>
      </div>
      {questionsFor == 'subcategory' ? (
        <div className="d-flex" style={{ justifyContent: 'space-between' }}>
          <button
            onClick={() => handlePrevious()}
            type="button"
            className="btn btn-sky my-2 me-2"
          >
            Previous
          </button>
          <button
            onClick={() => handleSubmit()}
            type="button"
            className="btn btn-success my-2 me-2"
          >
            Submit
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default SpecificQuestions;
