import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import { RESTRICT_STATEMENT } from '../../common/constants';
import { default as api, default as services } from '../../api/api';
import {
  getFormDataFromObject,
  validateFileExtensions,
} from '../../utils/helpers';
import ReactDatePicker from 'react-datepicker';
import { toastError, toastSuccess } from '../../common/UI/Toasts';
import deleteIcon from '../../assets/images/delete-icon.svg';

const FollowupQuesForm = ({ productId, subscriptionId }) => {
  const [data, setData] = useState([]);
  const [answerSubmit, setAnswerSubmit] = useState(false);
  const [restrictedMeds, setRestrictedMeds] = useState({
    mainQuestions: [],
    subQuestions: [],
  });

  const [showImageModal, setShowImageModal] = useState({
    show: false,
    prevUrl: null,
  });

  useEffect(() => {
    services.getProductById(productId).then((res) => {
      setData(res?.data?.data?.follow_up_questions);
    });
  }, []);

  const handleChange = (index, value) => {
    const dataClone = cloneDeep(data);
    const question = dataClone[index];
    if (question?.questionType?.value === 'input') {
      question.answer = value;
      dataClone[index] = question;
      setData(dataClone);
    } else if (question?.questionType?.value === 'boolean') {
      question.answer = value;

      // check for restriction
      if (
        (question?.preferredAnswer?.value === 'yesRestrict' &&
          question?.answer === 'yes') ||
        (question?.preferredAnswer?.value === 'noRestrict' &&
          question?.answer === 'no')
      ) {
        if (!restrictedMeds.mainQuestions.includes(index))
          setRestrictedMeds((prev) => {
            return { ...prev, mainQuestions: [...prev.mainQuestions, index] };
          });
        question.hasMarkedRestricted = true;
        question.warningMessage = RESTRICT_STATEMENT;
      } else {
        if (
          question?.preferredAnswer?.value === 'yesRestrict' ||
          question?.preferredAnswer?.value === 'noRestrict'
        ) {
          setRestrictedMeds((prev) => {
            return {
              ...prev,
              mainQuestions: prev.mainQuestions.filter((el) => el !== index),
            };
          });

          question.warningMessage = '';
        }
      }
      dataClone[index] = question;
      setData(dataClone);
    } else if (question?.questionType?.value === 'select') {
      if (!question?.isSingle) {
        // multi select
        if (question?.answer?.length) {
          if (question.answer.includes(value)) {
            let answer = question.answer.filter((el) => el !== value);
            question.answer = answer;
          } else {
            question.answer.push(value);
          }
        } else {
          question.answer = [value];
        }
      } else {
        // single select
        question.answer = [value];
      }
      dataClone[index] = question;
      setData(dataClone);
    } else if (question?.questionType?.value === 'file') {
      // upload photo to s3 directly
      if (!value) {
        // delete image
        if (question?.answer?.key)
          services.deleteImage(question.answer.key).then((res) => {
            question.answer = null;
            dataClone[index] = question;
            setData(dataClone);
          });
      } else {
        if (question?.answer?.key) {
          // if image is already there, delete the prev one n then add a new img
          services.deleteImage(question?.answer?.key).then((res) => {
            question.answer = null;
            services
              .uploadImage(getFormDataFromObject({ photos: value.answer }))
              .then((res) => {
                question.answer =
                  res.data.data.photos.length && res.data.data.photos[0];
                dataClone[index] = question;
                dataClone[index] = question;
                setData(dataClone);
              })
              .catch((err) => {
                console.log(err);
              });
          });
        } else {
          services
            .uploadImage(getFormDataFromObject({ photos: value.answer }))
            .then((res) => {
              question.answer =
                res.data.data.photos.length && res.data.data.photos[0];
              dataClone[index] = question;
              setData(dataClone);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    } else if (question?.questionType?.value === 'date') {
      var date = new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate()));
      question.answer = date;
      dataClone[index] = question;
      setData(dataClone);
    }
  };

  const handleSubQuestionChange = (mainIndex, index, value) => {
    const dataClone = cloneDeep(data);
    const question = dataClone[mainIndex];

    const subQuestion = question?.subQuestions[index];
    if (subQuestion?.questionType?.value === 'input') {
      subQuestion.answer = value;
      question.subQuestions[index] = subQuestion;
      dataClone[mainIndex] = question;
      setData(dataClone);
    } else if (subQuestion?.questionType?.value === 'boolean') {
      subQuestion.answer = value;

      // check for restriction
      if (
        (subQuestion?.preferredAnswer?.value === 'yesRestrict' &&
          subQuestion?.answer === 'yes') ||
        (subQuestion?.preferredAnswer?.value === 'noRestrict' &&
          subQuestion?.answer === 'no')
      ) {
        setRestrictedMeds((prev) => {
          return { ...prev, subQuestions: [...prev.subQuestions, index] };
        });
        subQuestion.warningMessage = RESTRICT_STATEMENT;
        subQuestion.hasMarkedRestricted = true;
      } else {
        if (
          subQuestion?.preferredAnswer?.value === 'yesRestrict' ||
          subQuestion?.preferredAnswer?.value === 'noRestrict'
        ) {
          setRestrictedMeds((prev) => {
            return {
              ...prev,
              subQuestions: prev.subQuestions.filter((el) => el !== index),
            };
          });
          subQuestion.warningMessage = '';
        }
      }
      question.subQuestions[index] = subQuestion;
      dataClone[mainIndex] = question;
      setData(dataClone);
    } else if (subQuestion?.questionType?.value === 'date') {
      var date = new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate()));
      subQuestion.answer = date;
      question.subQuestions[index] = subQuestion;
      dataClone[mainIndex] = question;
      setData(dataClone);
    } else if (subQuestion?.questionType?.value === 'file') {
      // upload photo to s3 directly
      if (!value) {
        // delete image
        if (subQuestion?.answer?.key)
          services.deleteImage(subQuestion.answer.key).then((res) => {
            subQuestion.answer = null;
            question.subQuestions[index] = subQuestion;

            dataClone[mainIndex] = question;
            setData(dataClone);
          });
      } else {
        if (subQuestion?.answer?.key) {
          // if image is already there, delete the prev one n then add a new img
          services.deleteImage(subQuestion?.answer?.key).then((res) => {
            subQuestion.answer = null;
            services
              .uploadImage(getFormDataFromObject({ photos: value.answer }))
              .then((res) => {
                subQuestion.answer =
                  res.data.data.photos.length && res.data.data.photos[0];
                question.subQuestions[index] = subQuestion;
                dataClone[mainIndex] = question;
                setData(dataClone);
              })
              .catch((err) => {
                console.log(err);
              });
          });
        } else {
          services
            .uploadImage(getFormDataFromObject({ photos: value.answer }))
            .then((res) => {
              subQuestion.answer =
                res.data.data.photos.length && res.data.data.photos[0];
              question.subQuestions[index] = subQuestion;
              dataClone[mainIndex] = question;
              setData(dataClone);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    }
  };

  const questionHTML = (ques, mainQuestionIndex, index) => {
    if (ques.questionType?.value === 'input') {
      return (
        <>
          {/* <label className="my-2">{ques?.question}</label> */}
          {ques?.isHTML ? (
            <label
              dangerouslySetInnerHTML={{
                __html: ques?.question,
              }}
              className={`second my-2 grey-anchor-a `}
            />
          ) : (
            <label className={`second my-2`}>{ques?.question}</label>
          )}
          <textarea
            className="form-control pt-3 mb-md-5 mb-2"
            rows="5"
            value={ques?.answer || ''}
            onChange={(e) => {
              handleSubQuestionChange(mainQuestionIndex, index, e.target.value);
            }}
          ></textarea>
        </>
      );
    } else if (ques.questionType?.value === 'boolean') {
      const buttonClasses = `btn border-radius-24 fs-16 off_btn ms-3`;
      return (
        <>
          <div className={`d-md-flex custom_mb_set align-items-start`}>
            {/* <p className={`second my-2`}>{ques?.question}</p> */}
            {ques?.isHTML ? (
              <p
                className={`second my-2 grey-anchor-a `}
                dangerouslySetInnerHTML={{
                  __html: ques?.question,
                }}
              />
            ) : (
              <p className={`second my-2`}>{ques?.question}</p>
            )}
            <div className="ms-auto d-flex">
              <button
                className={`${buttonClasses}  ${
                  ques?.answer === 'yes' ? 'button_active' : 'button_inactive'
                }`}
                onClick={() => {
                  handleSubQuestionChange(mainQuestionIndex, index, 'yes');
                }}
              >
                Yes
              </button>
              <button
                className={`${buttonClasses}  ${
                  ques?.answer === 'no' ? 'button_active' : 'button_inactive'
                }`}
                onClick={() => {
                  handleSubQuestionChange(mainQuestionIndex, index, 'no');
                }}
              >
                No
              </button>
            </div>
          </div>
          <span className="col-md-10 fs-16" style={{ color: 'red' }}>
            {ques?.warningMessage}
          </span>
        </>
      );
    } else if (ques.questionType?.value === 'date') {
      return (
        <div className="row" key={ques?._id}>
          {ques?.isHTML ? (
            <label
              dangerouslySetInnerHTML={{
                __html: ques?.question,
              }}
              className={`second my-2 grey-anchor-a  col-12 col-lg-8 col-md-8 `}
            />
          ) : (
            <label className={`second my-2 col-12 col-lg-8 col-md-8`}>
              {ques?.question}
            </label>
          )}
          <div className="col-12 col-lg-4 col-md-4 mb-2">
            <ReactDatePicker
              onChange={(value) => {
                handleSubQuestionChange(mainQuestionIndex, index, value);
              }}
              style={{ zIndex: 9999 }}
              selected={ques?.answer}
              className="form-control form-input px-4 custom-border"
            />
          </div>
        </div>
      );
    } else if (ques.questionType?.value === 'file') {
      return (
        <div key={ques?._id}>
          {ques?.isHTML ? (
            <label
              dangerouslySetInnerHTML={{
                __html: ques?.question,
              }}
              className={`second my-2 grey-anchor-a `}
            />
          ) : (
            <label className={`second my-2`}>{ques?.question}</label>
          )}
          <div className="d-flex align-items-center">
            <label
              className="ms-3 p-2 text-center mt-2 mx-2 d-flex align-items-center justify-content-center"
              style={{
                borderRadius: '32px',
                width: '125px',
                height: '52px',
                border: '1px solid #ccc',
                display: 'inline-block',
                padding: ' 10px 22px',
                cursor: 'pointer',
                fontSize: '15px',
              }}
            >
              Choose File
              <input
                type="file"
                accept="image/png, image/svg, image/jpeg, image/jpg, image/PNG, image/SVG, image/JPEG, image/JPG, image/webp"
                style={{ width: '300px', display: 'none' }}
                onChange={(e) => {
                  if (validateFileExtensions(e.target.files[0])) {
                    handleSubQuestionChange(mainQuestionIndex, index, {
                      answer: e.target.files[0],
                      prevUrl: URL.createObjectURL(e.target.files[0]),
                    });
                  } else {
                    toastError('Please select a valid image!');
                  }
                }}
              />
            </label>
            <div
              className={`form-check-label mt-2 d-flex align-items-center`}
              style={{ width: '200px', wordBreak: 'break-all' }}
            >
              <h6>
                {ques?.answer?.location ? (
                  <span
                    className="img_name"
                    onClick={() => {
                      setShowImageModal({
                        show: true,
                        prevUrl: ques?.answer?.location,
                      });
                    }}
                  >
                    {ques?.answer?.originalname}
                  </span>
                ) : (
                  <span className="no_img_text">No file chosen</span>
                )}
              </h6>
              <span
                className="delete_icon d-flex align-items-center rounded-circle ms-3"
                onClick={() => {
                  handleSubQuestionChange(mainQuestionIndex, index, null);
                }}
                title="Delete"
              >
                <img src={deleteIcon} height="40" width="40" alt="delete" />
              </span>
            </div>
          </div>
          <hr />
        </div>
      );
    }
  };

  const handleSubmit = () => {
    setAnswerSubmit(true);
    // Validation for questions

    // checking if all questions are answerd or not
    let allQuestionsAreAnswered = data.every(
      (el) => el?.answer && el?.answer != '',
    );

    if (allQuestionsAreAnswered) {
      // check if all sub questions are answerd or not
      let allSubQuesAreAnswered = data?.every((el) => {
        // because input type questions don't have sub questions
        if (el?.questionType?.value === 'boolean') {
          const subQuesList = el?.subQuestions?.filter(
            (elem) => elem?.selectedAnswer?.value === el?.answer,
          );
          return subQuesList?.every((element) => element?.answer != '');
        }
        return true;
      });

      if (allSubQuesAreAnswered) {
        services
          .updateSubscription(subscriptionId, { follow_up_ans: data })
          .then((res) => {
            toastSuccess('Answers Updated Successfully');
          });
      } else {
        setAnswerSubmit(false);
        toastError('All SubQuestions are mandatory to answer');
      }
    } else {
      setAnswerSubmit(false);
      toastError('All Questions are mandatory to answer');
    }
  };

  return (
    <section className="mt-5 mb-5 general_health_main">
      <div className="container container-full-width general_health_container">
        <div className="row">
          <div className="col-md-12 step_custom">
            <div className="wizard">
              <div className="general_health_main">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <p className="title mb-md-3 mb-2 col-md-8"></p>
                    </div>
                  </div>
                  {data?.length
                    ? data?.map((el, index) => {
                        if (el?.questionType?.value === 'input') {
                          return (
                            <div className="row" key={el?._id}>
                              {el?.isHTML ? (
                                <label
                                  dangerouslySetInnerHTML={{
                                    __html: el?.question,
                                  }}
                                  className={`second my-2 grey-anchor-a `}
                                />
                              ) : (
                                <label className={`second my-2`}>
                                  {el?.question}
                                </label>
                              )}
                              <textarea
                                className="form-control pt-3 mb-md-3 mb-2"
                                rows="2"
                                value={el?.answer || ''}
                                onChange={(e) => {
                                  handleChange(index, e.target.value);
                                }}
                              ></textarea>
                              <hr />
                            </div>
                          );
                        } else if (el?.questionType?.value === 'boolean') {
                          const buttonClasses = `btn border-radius-24 fs-16 off_btn ms-3`;
                          let yesQuestions = [];
                          el?.subQuestions?.forEach((el, index) => {
                            if (el?.selectedAnswer?.value === 'yes') {
                              yesQuestions.push({ ...el, subQuesIndex: index });
                            }
                          });
                          let noQuestions = [];
                          el?.subQuestions?.forEach((el, index) => {
                            if (el?.selectedAnswer?.value === 'no') {
                              noQuestions.push({ ...el, subQuesIndex: index });
                            }
                          });

                          return (
                            <div className="row" key={el?._id}>
                              <div className="col-md-12">
                                <div
                                  className={`d-md-flex custom_mb_set align-items-start`}
                                >
                                  {el?.isHTML ? (
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: el?.question,
                                      }}
                                      className={`second my-2 grey-anchor-a `}
                                    />
                                  ) : (
                                    <p className={`second my-2`}>
                                      {el?.question}
                                    </p>
                                  )}
                                  <div className="ms-auto d-flex">
                                    <button
                                      className={`${buttonClasses} ${
                                        el?.answer === 'yes'
                                          ? 'button_active'
                                          : 'button_inactive'
                                      }`}
                                      onClick={() => {
                                        handleChange(index, 'yes');
                                      }}
                                    >
                                      Yes
                                    </button>
                                    <button
                                      className={`${buttonClasses} ${
                                        el?.answer === 'no'
                                          ? 'button_active'
                                          : 'button_inactive'
                                      }`}
                                      onClick={() => {
                                        handleChange(index, 'no');
                                      }}
                                    >
                                      No
                                    </button>
                                  </div>
                                </div>
                                <span
                                  className="col-md-10 fs-16"
                                  style={{ color: 'red' }}
                                >
                                  {el?.warningMessage}
                                </span>
                              </div>
                              <div className="col-md-8">
                                {el?.subQuestions?.length ? (
                                  el?.answer === 'yes' &&
                                  yesQuestions.length ? (
                                    <div className="mx-2">
                                      {yesQuestions.map((el) => {
                                        return questionHTML(
                                          el,
                                          index,
                                          el?.subQuesIndex,
                                        );
                                      })}
                                    </div>
                                  ) : el?.answer === 'no' &&
                                    noQuestions.length ? (
                                    <div>
                                      {noQuestions.map((el) => {
                                        return questionHTML(
                                          el,
                                          index,
                                          el?.subQuesIndex,
                                        );
                                      })}
                                    </div>
                                  ) : null
                                ) : null}
                              </div>
                              <div></div>
                              <hr />
                            </div>
                          );
                        } else if (el?.questionType?.value === 'select') {
                          let subQuestionsForSelectedOptions = [];
                          el?.subQuestions.forEach((subQues, index) => {
                            if (
                              el?.answer?.includes(
                                subQues?.selectedAnswer?.value,
                              )
                            ) {
                              subQuestionsForSelectedOptions.push({
                                ...subQues,
                                subQuesIndex: index,
                              });
                            }
                          });
                          return (
                            <div className="row" key={el?._id}>
                              <div
                                className={`col-xl-6 col-lg-6 col-md-6 col-12 custom_mb_set align-items-start`}
                              >
                                {el?.isHTML ? (
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: el?.question,
                                    }}
                                    className={`second my-2 grey-anchor-a `}
                                  />
                                ) : (
                                  <p className={`second my-2`}>
                                    {el?.question}
                                  </p>
                                )}
                              </div>
                              <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                {el?.options.map((elem, i) => {
                                  return (
                                    <div
                                      key={i}
                                      className="option d-flex align-items-center"
                                    >
                                      <input
                                        type={`${
                                          el?.isSingle ? 'radio' : 'checkbox'
                                        }`}
                                        name={`options${index}`}
                                        id={`index${index}option${i}`}
                                        checked={
                                          el?.answer &&
                                          el?.answer.includes(elem)
                                        }
                                        onChange={() => {
                                          handleChange(index, elem);
                                        }}
                                        className="filter-checkbox styled_checkbox"
                                      />
                                      <label
                                        style={{
                                          marginBottom: '0px',
                                          color:
                                            el?.answer &&
                                            el?.answer.includes(elem)
                                              ? '#088fff'
                                              : 'black',
                                        }}
                                        htmlFor={`index${index}option${i}`}
                                      >
                                        {elem}
                                      </label>
                                    </div>
                                  );
                                })}
                              </div>
                              <div className="col-md-8">
                                {el?.subQuestions?.length ? (
                                  <>
                                    {subQuestionsForSelectedOptions?.map(
                                      (elem) => {
                                        return questionHTML(
                                          elem,
                                          index,
                                          elem?.subQuesIndex,
                                        );
                                      },
                                    )}
                                  </>
                                ) : null}
                              </div>
                              <hr />
                            </div>
                          );
                        } else if (el?.questionType?.value === 'file') {
                          return (
                            <div key={el?._id}>
                              {el?.isHTML ? (
                                <label
                                  dangerouslySetInnerHTML={{
                                    __html: el?.question,
                                  }}
                                  className={`second my-2 grey-anchor-a `}
                                />
                              ) : (
                                <label className={`second my-2`}>
                                  {el?.question}
                                </label>
                              )}
                              <div className="d-flex align-items-center">
                                <label
                                  className="ms-3 p-2 text-center mt-2 mx-2 d-flex align-items-center justify-content-center"
                                  style={{
                                    borderRadius: '32px',
                                    width: '125px',
                                    height: '52px',
                                    border: '1px solid #ccc',
                                    display: 'inline-block',
                                    padding: ' 10px 22px',
                                    cursor: 'pointer',
                                    fontSize: '15px',
                                  }}
                                >
                                  Choose File
                                  <input
                                    type="file"
                                    accept="image/png, image/svg, image/jpeg, image/jpg, image/PNG, image/SVG, image/JPEG, image/JPG, image/webp"
                                    style={{ width: '300px', display: 'none' }}
                                    onChange={(e) => {
                                      if (
                                        validateFileExtensions(
                                          e.target.files[0],
                                        )
                                      ) {
                                        handleChange(index, {
                                          answer: e.target.files[0],
                                          prevUrl: URL.createObjectURL(
                                            e.target.files[0],
                                          ),
                                        });
                                      } else {
                                        toastError(
                                          'Please select a valid image!',
                                        );
                                      }
                                    }}
                                  />
                                </label>
                                <div
                                  className={`form-check-label mt-2 d-flex align-items-center`}
                                  style={{
                                    width: '200px',
                                    wordBreak: 'break-all',
                                  }}
                                >
                                  <h6>
                                    {el?.answer?.location ? (
                                      <span
                                        className="img_name"
                                        onClick={() => {
                                          setShowImageModal({
                                            show: true,
                                            prevUrl: el?.answer?.location,
                                          });
                                        }}
                                      >
                                        {el?.answer?.originalname}
                                      </span>
                                    ) : (
                                      <span className="no_img_text">
                                        No file chosen
                                      </span>
                                    )}
                                  </h6>
                                  <span
                                    className="delete_icon d-flex align-items-center rounded-circle ms-3"
                                    onClick={() => {
                                      handleChange(index, null);
                                    }}
                                    title="Delete"
                                  >
                                    <img
                                      src="/images/delete-icon.svg"
                                      height="20"
                                      width="20"
                                      alt="delete"
                                    />
                                  </span>
                                </div>
                              </div>
                              <hr />
                            </div>
                          );
                        } else if (el?.questionType?.value === 'date') {
                          return (
                            <div className="row" key={el?._id}>
                              {el?.isHTML ? (
                                <label
                                  dangerouslySetInnerHTML={{
                                    __html: el?.question,
                                  }}
                                  className={`second my-2 grey-anchor-a  col-12 col-lg-8 col-md-8 `}
                                />
                              ) : (
                                <label
                                  className={`second my-2 col-12 col-lg-8 col-md-8`}
                                >
                                  {el?.question}
                                </label>
                              )}
                              <div className="col-12 col-lg-4 col-md-4 mb-2">
                                <ReactDatePicker
                                  onChange={(value) => {
                                    handleChange(index, value);
                                  }}
                                  style={{ zIndex: 9999 }}
                                  selected={el?.answer}
                                  className="form-control form-input px-4 "
                                />
                              </div>
                              <hr />
                            </div>
                          );
                        }
                      })
                    : null}
                  <div className="mt-2">
                    <button
                      className={`m-1 btn btn-primary border-radius-24 fs-16 btn_pd ${
                        restrictedMeds.mainQuestions.length !== 0 ||
                        restrictedMeds.subQuestions.length !== 0
                          ? 'cursor-notAllowed'
                          : 'cursor-pointer'
                      } shadow-none shadow-focus-none`}
                      disabled={
                        restrictedMeds.mainQuestions.length !== 0 ||
                        restrictedMeds.subQuestions.length !== 0 ||
                        answerSubmit
                      }
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showImageModal.show ? (
        <Modal
          show={showImageModal.show}
          onHide={() =>
            setShowImageModal((prev) => {
              return { show: !prev.show, prevUrl: null };
            })
          }
          centered={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>{`Image`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="p-2">
              <img
                src={showImageModal?.prevUrl}
                alt="answer"
                width=""
                height=""
                className="img-fluid w-100"
                style={{ borderRadius: '8px' }}
              />
            </div>
          </Modal.Body>
        </Modal>
      ) : null}
    </section>
  );
};
export default FollowupQuesForm;
