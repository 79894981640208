import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  logout,
  isUserAdmin,
  isUserDoctor,
  isUserPharmacist,
  getCurrentUserRole,
} from '../../utils/helpers';
import { toastSuccess } from '../../common/UI/Toasts';
import userImg from '../../assets/images/user_img.png';

// images and svgs
import logoAdmin from '../../assets/images/Farmeci-white.svg';
import logOutImg from '../../assets/images/arrow-bar-right.svg';
import setttingsIcon from '../../assets/images/settings.svg';
import bellIcon from '../../assets/images/bell.svg';
import searchIcon from '../../assets/images/search.svg';
import indentDecrease from '../../assets/images/indent-decrease.svg';
// import minLogo from '../../assets/images/fav.png';
import { Collapse } from 'react-bootstrap';
import services from './../../api/api';
import { useSelector } from 'react-redux';
import { headerTabs } from '../../common/constants';

const Header = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const fullname = localStorage.getItem('fullname');
  const firstName = localStorage.getItem('firstName');
  const userphoto = localStorage.getItem('userphoto');
  const { pathname } = useLocation();

  const [totalTotalDuplicateUsers, setTotalDuplicateUsers] = useState(0);
  const [isOpen, setIsOpen] = useState({
    userDropdown: false,
    searchOption: false,
    isHamburgerClicked: false,
    promotionTab: false,
    productTab: false,
    orderTab: pathname.includes('orders') ? true : false,
    gpLetters: false,
    userTab: false,
    stockTab: false,
    reportingTab: false,
    settingTab: false,
    meetingTab: false,
  });
  const [isActive, setIsActive] = useState('dashboard');
  const pharmacistRejectedOrders = useSelector(
    (state) => state.order.pharmacyRejectOrders,
  );
  const lexisNexisRejectedOrders = useSelector(
    (state) => state.order.lexisNexisRejectOrders,
  );

  useEffect(() => {
    if (isUserAdmin()) {
      services
        .getDuplicateUsers({
          perPage: 1,
          page: 1,
        })
        .then((res) => {
          setTotalDuplicateUsers(res?.data?.data?.totalDocs);
        })
        .catch((err) => {
          console.log('error', err);
        });
    }
    if (isUserPharmacist() || isUserDoctor()) {
      setIsOpen((prevState) => ({
        ...prevState,
        orderTab: true,
      }));
    }
    const { pathname } = location;
    setIsActive(pathname);
    setIsOpen((prevState) => ({
      ...prevState,
      orderTab: pathname.includes('order'),
    }));
  }, []);

  useEffect(() => {
    // set the tabs open by checking path
    if (pathname) {
      let path = pathname.slice(1);
      setIsActive(path);
      Object.keys(headerTabs).forEach((el) => {
        if (headerTabs[el].includes(path)) {
          setIsOpen((prev) => {
            return {
              ...prev,
              [el]: true,
            };
          });
        }
      });
    }
  }, [pathname]);

  return (
    <>
      <nav
        className="navbar p-lg-0 navbar-expand-lg navbar-dark nav_bar_main fixed-top top_main"
        id="mainNav"
      >
        <Link className="navbar-brand logo_main me-0 text-center" to={''}>
          {/* <img
            src={logoAdmin}
            className="logo_1 ms-lg-0 ms-2"
            width="110"
            alt="Logo"
          />
          <img
            src={minLogo}
            className="logo_2"
            height="45"
            width="55"
            alt="Logo"
          /> */}
        </Link>
        {/* Responsive code ...hamburger button */}
        <button
          className="navbar-toggler shadow-none navbar-toggler-right"
          type="button"
          // data-toggle="collapse"
          // data-target="#navbarResponsive"
          // aria-controls="navbarResponsive"
          // aria-expanded="false"
          // aria-label="Toggle navigation"
          onClick={() => {
            setIsOpen((prevState) => ({
              ...prevState,
              isHamburgerClicked: !prevState.isHamburgerClicked,
            }));
          }}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <Collapse in={isOpen.isHamburgerClicked}>
          <div
            className="navbar-collapse custom_navbar_collapse"
            id="navbarResponsive"
          >
            <ul
              className="navbar-nav custom_nav navbar-sidenav custom_navbar_sidenav"
              id="exampleAccordion"
            >
              <li className="custom_nav_item">
                <Link
                  className={`nav-link custom_nav_link ${
                    isActive == 'dashboard' ? 'active' : null
                  }`}
                  to=""
                  reloadDocument
                  title="Dashboard"
                  onClick={() => {
                    setIsOpen((prevState) => ({
                      ...prevState,
                      productTab: false,
                      promotionTab: false,
                      orderTab: false,
                      meetingTab: false,
                      stockTab: false,
                      userTab: false,
                      isHamburgerClicked: false,
                      settingTab: false,
                    }));
                    setIsActive('dashboard');
                  }}
                >
                  <i className="fa fa-fw fa-dashboard"></i>
                  <span className="nav_link_text">Dashboard</span>
                </Link>
              </li>
              {isUserAdmin() ? (
                <li className="custom_nav_item">
                  <Link
                    className={`nav-link custom_nav_link nav-link-collapse custom_nav_link_collapse ${
                      isOpen.productTab ? null : 'collapsed'
                    } ${
                      isActive.includes('product') && isOpen.productTab
                        ? 'active'
                        : null
                    }`}
                    title="Product Management"
                    to="#"
                    // aria-controls="collapseComponents"
                    // aria-expanded={isOpen.productTab ? true : false}
                    onClick={() => {
                      setIsOpen((prevState) => ({
                        ...prevState,
                        orderTab: false,
                        promotionTab: false,
                        stockTab: false,
                        meetingTab: false,
                        userTab: false,
                        settingTab: false,
                        productTab: !prevState.productTab,
                      }));
                      setIsActive('product');
                    }}
                  >
                    <i className="fa fa-cube fa-fw "></i>
                    <span className="nav_link_text">Product Management</span>
                  </Link>
                  <Collapse in={isOpen.productTab}>
                    <ul
                      className="sidenav_second_level"
                      id="collapseComponents"
                    >
                      <li
                        className={`${isActive == 'product' ? 'active' : null}`}
                      >
                        <Link
                          to="/product"
                          title="All Products"
                          reloadDocument
                          onClick={() => {
                            setIsActive('product');
                            setIsOpen((prevState) => ({
                              ...prevState,
                              isHamburgerClicked: false,
                            }));
                          }}
                        >
                          All Products
                        </Link>
                      </li>
                      <li
                        className={`${
                          isActive == 'category' ? 'active' : null
                        }`}
                      >
                        <Link
                          to="/category"
                          title="Category Management"
                          reloadDocument
                          onClick={() => {
                            setIsActive('category');
                            setIsOpen((prevState) => ({
                              ...prevState,
                              isHamburgerClicked: false,
                            }));
                          }}
                        >
                          Category Management
                        </Link>
                      </li>
                      <li
                        className={`${isActive == 'keyword' ? 'active' : null}`}
                      >
                        <Link
                          to="/keyword"
                          title="Keyword Management"
                          reloadDocument
                          onClick={() => {
                            setIsActive('keyword');
                            setIsOpen((prevState) => ({
                              ...prevState,
                              isHamburgerClicked: false,
                            }));
                          }}
                        >
                          Keyword Management
                        </Link>
                      </li>
                      <li
                        className={`${isActive == 'brand' ? 'active' : null}`}
                      >
                        <Link
                          to="/brand"
                          title="Brand Management"
                          reloadDocument
                          onClick={() => {
                            setIsActive('brand');
                            setIsOpen((prevState) => ({
                              ...prevState,
                              isHamburgerClicked: false,
                            }));
                          }}
                        >
                          Brand Management
                        </Link>
                      </li>
                      <li
                        className={`${
                          isActive == 'featured-product' ? 'active' : null
                        }`}
                      >
                        <Link
                          to="/featured-product"
                          title="Featured Products"
                          reloadDocument
                          onClick={() => {
                            setIsActive('featured-product');
                            setIsOpen((prevState) => ({
                              ...prevState,
                              isHamburgerClicked: false,
                            }));
                          }}
                        >
                          Featured Products
                        </Link>
                      </li>
                      <li
                        className={`${
                          isActive == 'general-health-questions'
                            ? 'active'
                            : null
                        }`}
                      >
                        <Link
                          to="/general-health-questions"
                          reloadDocument
                          title="General Health Questions"
                          onClick={() => {
                            setIsActive('general-health-questions');
                            setIsOpen((prevState) => ({
                              ...prevState,
                              isHamburgerClicked: false,
                            }));
                          }}
                        >
                          General Health Questions
                        </Link>
                      </li>
                      <li
                        className={`${
                          isActive == 'health-goals' ? 'active' : null
                        }`}
                      >
                        <Link
                          to="/health-goals"
                          title="Health Goals"
                          reloadDocument
                          onClick={() => {
                            setIsActive('health-goals');
                            setIsOpen((prevState) => ({
                              ...prevState,
                              isHamburgerClicked: false,
                            }));
                          }}
                        >
                          Health Goals
                        </Link>
                      </li>
                      {isUserAdmin() ? (
                        <>
                          <li
                            className={`${
                              isActive == 'google-ads' ? 'active' : null
                            }`}
                          >
                            <Link
                              to="/meds-google-ads"
                              title="Google Ads"
                              reloadDocument
                              onClick={() => {
                                setIsActive('meds-google-ads');
                                setIsOpen((prevState) => ({
                                  ...prevState,
                                  isHamburgerClicked: false,
                                }));
                              }}
                            >
                              Google Ads - Medication
                            </Link>
                          </li>
                          <li
                            className={`${
                              isActive == 'google-ads' ? 'active' : null
                            }`}
                          >
                            <Link
                              to="/subcat-google-ads"
                              title="Google Ads"
                              reloadDocument
                              onClick={() => {
                                setIsActive('subcat-google-ads');
                                setIsOpen((prevState) => ({
                                  ...prevState,
                                  isHamburgerClicked: false,
                                }));
                              }}
                            >
                              Google Ads - SubCategory
                            </Link>
                          </li>
                        </>
                      ) : null}
                    </ul>
                  </Collapse>
                </li>
              ) : null}
              {isUserAdmin() ? (
                <li className="custom_nav_item">
                  <Link
                    className={`nav-link custom_nav_link nav-link-collapse custom_nav_link_collapse ${
                      isOpen.promotionTab ? null : 'collapsed'
                    } ${
                      isActive.includes('promotion') && isOpen.promotionTab
                        ? 'active'
                        : null
                    }`}
                    title="Promotion Management"
                    to="#"
                    onClick={() => {
                      setIsOpen((prevState) => ({
                        ...prevState,
                        promotionTab: !prevState.promotionTab,
                        productTab: false,
                        orderTab: false,
                        userTab: false,
                        meetingTab: false,
                        settingTab: false,
                        stockTab: false,
                      }));
                      setIsActive('promotion');
                    }}
                  >
                    <i className="fa fa-tag fa-fw"></i>
                    <span className="nav_link_text">Promotion Management</span>
                  </Link>
                  <Collapse in={isOpen.promotionTab}>
                    <ul
                      className="sidenav_second_level"
                      id="collapseComponents"
                    >
                      <li
                        className={`${
                          isActive == 'brand-promotion' ||
                          isActive == 'promotion'
                            ? 'active'
                            : null
                        }`}
                      >
                        <Link
                          to="/brand-promotion"
                          title="Brand Promotion"
                          reloadDocument
                          onClick={() => {
                            setIsActive('brand-promotion');
                            setIsOpen((prevState) => ({
                              ...prevState,
                              isHamburgerClicked: false,
                            }));
                          }}
                        >
                          Brand Promotion
                        </Link>
                      </li>
                      <li
                        className={`${
                          isActive == 'product-promotion' ? 'active' : null
                        }`}
                      >
                        <Link
                          to="/product-promotion"
                          title="Product Promotion"
                          reloadDocument
                          onClick={() => {
                            setIsActive('product-promotion');
                            setIsOpen((prevState) => ({
                              ...prevState,
                              isHamburgerClicked: false,
                            }));
                          }}
                        >
                          Product Promotion
                        </Link>
                      </li>
                      <li
                        className={`${
                          isActive == 'offer-title' || isActive == 'promotion'
                            ? 'active'
                            : null
                        }`}
                      >
                        <Link
                          to="/offer-title"
                          title="Offer Title"
                          reloadDocument
                          onClick={() => {
                            setIsActive('offer-title');
                            setIsOpen((prevState) => ({
                              ...prevState,
                              isHamburgerClicked: false,
                            }));
                          }}
                        >
                          Offer Title
                        </Link>
                      </li>
                      <li
                        className={`${
                          isActive == 'offer-title' || isActive == 'promotion'
                            ? 'active'
                            : null
                        }`}
                      >
                        <Link
                          to="/referral"
                          title="Referral Management"
                          reloadDocument
                          onClick={() => {
                            setIsActive('referral');
                            setIsOpen((prevState) => ({
                              ...prevState,
                              isHamburgerClicked: false,
                            }));
                          }}
                        >
                          Referral Management
                        </Link>
                      </li>
                    </ul>
                  </Collapse>
                </li>
              ) : null}
              {isUserAdmin() ? (
                <li className="custom_nav_item">
                  <Link
                    className={`nav-link custom_nav_link ${
                      isActive == 'couponcode' ? 'active' : null
                    }`}
                    to="/couponcode"
                    title="Coupon Code Management"
                    onClick={() => {
                      setIsOpen((prevState) => ({
                        ...prevState,
                        productTab: false,
                        promotionTab: false,
                        orderTab: false,
                        stockTab: false,
                        meetingTab: false,
                        userTab: false,
                        settingTab: false,
                        isHamburgerClicked: false,
                      }));
                      setIsActive('couponcode');
                    }}
                  >
                    <i className="fa fa-certificate fa-fw"></i>
                    <span className="nav_link_text">
                      Coupon Code Management
                    </span>
                  </Link>
                </li>
              ) : null}

              {isUserAdmin() ? (
                <li className="custom_nav_item">
                  <Link
                    className={`nav-link custom_nav_link ${
                      isActive == 'front-page-html' ? 'active' : null
                    }`}
                    to="/front-page-html"
                    title="Front Page HTML"
                    onClick={() => {
                      setIsOpen((prevState) => ({
                        ...prevState,
                        productTab: false,
                        promotionTab: false,
                        orderTab: false,
                        meetingTab: false,
                        stockTab: false,
                        userTab: false,
                        settingTab: false,
                        isHamburgerClicked: false,
                      }));
                      setIsActive('front-page-html');
                    }}
                  >
                    <i className="fa fa-font fa-fw"></i>
                    <span className="nav_link_text">Front Page HTML</span>
                  </Link>
                </li>
              ) : null}

              {/* Order Management */}
              <li className="custom_nav_item">
                <Link
                  className={`nav-link custom_nav_link nav-link-collapse custom_nav_link_collapse ${
                    isActive.includes('order') && isOpen.orderTab
                      ? null
                      : 'collapsed'
                  } ${
                    isActive.includes('order') && isOpen.orderTab
                      ? 'active'
                      : null
                  }`}
                  title="Order Management"
                  to="#"
                  onClick={() => {
                    setIsOpen((prevState) => ({
                      ...prevState,
                      productTab: false,
                      promotionTab: false,
                      userTab: false,
                      orderTab: !prevState.orderTab,
                      stockTab: false,
                      settingTab: false,
                      meetingTab: false,
                    }));
                    setIsActive('order');
                  }}
                >
                  <i className="fa fa-file-text-o fa-fw"></i>
                  <span className="nav_link_text">Order Management</span>
                </Link>
                {/* For Admin */}

                {isUserAdmin() ? (
                  <Collapse in={isOpen.orderTab && isActive.includes('order')}>
                    <ul
                      className="sidenav_second_level"
                      id="collapseComponents"
                    >
                      <li
                        className={`${
                          isActive == 'orders' || isActive.includes('vieworder')
                            ? 'active'
                            : null
                        }`}
                      >
                        <Link
                          to="/orders"
                          title="All Orders"
                          reloadDocument
                          onClick={() => {
                            setIsActive('orders');
                            setIsOpen((prevState) => ({
                              ...prevState,
                              isHamburgerClicked: false,
                            }));
                          }}
                        >
                          All Orders
                        </Link>
                      </li>
                      <li
                        className={`${
                          isActive == 'orders-pending' ? 'active' : null
                        }`}
                      >
                        <Link
                          to="/orders-pending"
                          title="Pending Orders"
                          reloadDocument
                          onClick={() => {
                            setIsActive('orders-pending');
                            setIsOpen((prevState) => ({
                              ...prevState,
                              isHamburgerClicked: false,
                            }));
                          }}
                        >
                          Pending Orders
                        </Link>
                      </li>
                      <li
                        className={`${
                          isActive == 'orders-dispatched' ? 'active' : null
                        }`}
                      >
                        <Link
                          to="/orders-dispatched"
                          title="Dispatched Orders"
                          reloadDocument
                          onClick={() => {
                            setIsActive('orders-dispatched');
                            setIsOpen((prevState) => ({
                              ...prevState,
                              isHamburgerClicked: false,
                            }));
                          }}
                        >
                          Dispatched Orders
                        </Link>
                      </li>
                      <li
                        className={`${
                          isActive == 'orders-cancelled' ? 'active' : null
                        }`}
                      >
                        <Link
                          to="/orders-cancelled"
                          title="Cancelled Orders"
                          reloadDocument
                          onClick={() => {
                            setIsActive('orders-cancelled');
                            setIsOpen((prevState) => ({
                              ...prevState,
                              isHamburgerClicked: false,
                            }));
                          }}
                        >
                          Cancelled Orders
                        </Link>
                      </li>
                      <li
                        className={`${
                          isActive == 'mds-orders' ? 'active' : null
                        }`}
                      >
                        <Link
                          to="/mds-orders"
                          title="PCP Orders"
                          reloadDocument
                          onClick={() => {
                            setIsActive('mds-orders');
                            setIsOpen((prevState) => ({
                              ...prevState,
                              isHamburgerClicked: false,
                            }));
                          }}
                        >
                          PCP Orders
                        </Link>
                      </li>
                      <li
                        className={`${
                          isActive == 'orders-pharmacist-rejected'
                            ? 'active'
                            : null
                        }`}
                      >
                        <Link
                          to="/orders-pharmacist-rejected"
                          reloadDocument
                          title="Pharmacist Rejected Orders"
                          onClick={() => {
                            setIsActive('orders-pharmacist-rejected');
                            setIsOpen((prevState) => ({
                              ...prevState,
                              isHamburgerClicked: false,
                            }));
                          }}
                        >
                          {`Pharmacist Rejected Orders(${
                            pharmacistRejectedOrders?.count || 0
                          })`}
                        </Link>
                      </li>
                      <li
                        className={`${
                          isActive == 'orders-stripe-rejected' ? 'active' : null
                        }`}
                      >
                        <Link
                          to="/orders-stripe-rejected"
                          title="Stripe Rejected Orders"
                          reloadDocument
                          onClick={() => {
                            setIsActive('orders-stripe-rejected');
                            setIsOpen((prevState) => ({
                              ...prevState,
                              isHamburgerClicked: false,
                            }));
                          }}
                        >
                          {`Stripe Rejected Orders(${
                            lexisNexisRejectedOrders?.count || 0
                          })`}
                        </Link>
                      </li>
                      <li
                        className={`${
                          isActive == 'orders-subscription' ? 'active' : null
                        }`}
                      >
                        <Link
                          to="/orders-subscription"
                          title="Subscription Orders"
                          reloadDocument
                          onClick={() => {
                            setIsActive('orders-subscription');
                            setIsOpen((prevState) => ({
                              ...prevState,
                              isHamburgerClicked: false,
                            }));
                          }}
                        >
                          {`Subscription Orders`}
                        </Link>
                      </li>
                      <li
                        className={`${
                          isActive == 'orders-subscription-paused'
                            ? 'active'
                            : null
                        }`}
                      >
                        <Link
                          to="/orders-subscription-paused"
                          title="Paused Subscriptions"
                          reloadDocument
                          onClick={() => {
                            setIsActive('orders-subscription-paused');
                            setIsOpen((prevState) => ({
                              ...prevState,
                              isHamburgerClicked: false,
                            }));
                          }}
                        >
                          {`Paused Subscriptions`}
                        </Link>
                      </li>
                      <li
                        className={`${
                          isActive == 'orders-subscription-cancelled'
                            ? 'active'
                            : null
                        }`}
                      >
                        <Link
                          to="/orders-subscription-cancelled"
                          title="Subscription Cancelled Orders"
                          reloadDocument
                          onClick={() => {
                            setIsActive('orders-subscription-cancelled');
                            setIsOpen((prevState) => ({
                              ...prevState,
                              isHamburgerClicked: false,
                            }));
                          }}
                        >
                          {`Subscription Cancelled Orders`}
                        </Link>
                      </li>
                    </ul>
                  </Collapse>
                ) : null}
                {/* For Pharmacist */}
                {isUserPharmacist() ? (
                  <Collapse in={isOpen.orderTab}>
                    <ul
                      className="sidenav_second_level"
                      id="collapseComponents"
                    >
                      <li
                        className={`${
                          isActive == 'orders-assigned' || isActive == 'order'
                            ? 'active'
                            : null
                        }`}
                      >
                        <Link
                          to="/orders-assigned"
                          title="Assigned Orders"
                          reloadDocument
                          onClick={() => {
                            setIsActive('orders-assigned');
                            setIsOpen((prevState) => ({
                              ...prevState,
                              isHamburgerClicked: false,
                            }));
                          }}
                        >
                          Assigned Orders
                        </Link>
                      </li>
                      <li
                        className={`${
                          isActive == 'orders-dispatched' ? 'active' : null
                        }`}
                      >
                        <Link
                          to="/orders-dispatched"
                          title="Dispatched Orders"
                          reloadDocument
                          onClick={() => {
                            setIsActive('orders-dispatched');
                            setIsOpen((prevState) => ({
                              ...prevState,
                              isHamburgerClicked: false,
                            }));
                          }}
                        >
                          Dispatched Orders
                        </Link>
                      </li>
                      <li
                        className={`${isActive == 'orders' ? 'active' : null}`}
                      >
                        <Link
                          to="/orders"
                          title="All Orders"
                          reloadDocument
                          onClick={() => {
                            setIsActive('orders');
                            setIsOpen((prevState) => ({
                              ...prevState,
                              isHamburgerClicked: false,
                            }));
                          }}
                        >
                          All Orders
                        </Link>
                      </li>
                    </ul>
                  </Collapse>
                ) : null}
                {/* For Doctor */}
                {isUserDoctor() ? (
                  <Collapse in={isOpen.orderTab}>
                    <ul
                      className="sidenav_second_level"
                      id="collapseComponents"
                    >
                      <li
                        className={`${
                          isActive == 'orders' || isActive == 'order'
                            ? 'active'
                            : null
                        }`}
                      >
                        <Link
                          to="/orders"
                          title="All Orders"
                          reloadDocument
                          onClick={() => {
                            setIsActive('orders');
                            setIsOpen((prevState) => ({
                              ...prevState,
                              isHamburgerClicked: false,
                            }));
                          }}
                        >
                          All Orders
                        </Link>
                      </li>
                      <li
                        className={`${
                          isActive == 'orders-pending' ? 'active' : null
                        }`}
                      >
                        <Link
                          to="/orders-pending"
                          title="Pending Orders"
                          reloadDocument
                          onClick={() => {
                            setIsActive('orders-pending');
                            setIsOpen((prevState) => ({
                              ...prevState,
                              isHamburgerClicked: false,
                            }));
                          }}
                        >
                          Pending Orders
                        </Link>
                      </li>
                      <li
                        className={`${
                          isActive == 'orders-approved' ? 'active' : null
                        }`}
                      >
                        <Link
                          to="/orders-approved"
                          title="Approved Orders"
                          reloadDocument
                          onClick={() => {
                            setIsActive('orders-approved');
                            setIsOpen((prevState) => ({
                              ...prevState,
                              isHamburgerClicked: false,
                            }));
                          }}
                        >
                          Approved Orders
                        </Link>
                      </li>
                      <li
                        className={`${
                          isActive == 'orders-dispatched' ? 'active' : null
                        }`}
                      >
                        <Link
                          to="/orders-dispatched"
                          title="Completed Orders"
                          reloadDocument
                          onClick={() => {
                            setIsActive('orders-dispatched');
                            setIsOpen((prevState) => ({
                              ...prevState,
                              isHamburgerClicked: false,
                            }));
                          }}
                        >
                          Completed Orders
                        </Link>
                      </li>
                    </ul>
                  </Collapse>
                ) : null}
              </li>

              <li className="custom_nav_item">
                <Link
                  className={`nav-link custom_nav_link nav-link-collapse custom_nav_link_collapse ${
                    isActive.includes('meeting') && isOpen.meetingTab
                      ? null
                      : 'collapsed'
                  } ${
                    isActive.includes('meeting') && isOpen.meetingTab
                      ? 'active'
                      : null
                  }`}
                  title="Meeting Management"
                  to="#"
                  onClick={() => {
                    setIsOpen((prevState) => ({
                      ...prevState,
                      productTab: false,
                      promotionTab: false,
                      userTab: false,
                      meetingTab: !prevState.meetingTab,
                      orderTab: false,
                      stockTab: false,
                      settingTab: false,
                    }));
                    setIsActive('meeting');
                  }}
                >
                  <i className="fa fa-file-text-o fa-fw"></i>
                  <span className="nav_link_text">Meeting Management</span>
                </Link>
                {isUserAdmin() ? (
                  <Collapse
                    in={isOpen.meetingTab && isActive.includes('meeting')}
                  >
                    <ul
                      className="sidenav_second_level"
                      id="collapseComponents"
                    >
                      <li
                        className={`${
                          isActive == 'meeting' ||
                          isActive.includes('viewmeeting')
                            ? 'active'
                            : null
                        }`}
                      >
                        <Link
                          to="/orders-meeting-list"
                          title="Orders Meeting List"
                          reloadDocument
                          onClick={() => {
                            setIsActive('viewmeeting');
                            setIsOpen((prevState) => ({
                              ...prevState,
                              isHamburgerClicked: false,
                            }));
                          }}
                        >
                          Meeting list
                        </Link>
                      </li>
                      <li
                        className={`${
                          isActive == 'meeting' ||
                          isActive.includes('viewmeeting')
                            ? 'active'
                            : null
                        }`}
                      >
                        <Link
                          to="/old-orders-meeting-list"
                          title="Completed Orders Meeting List"
                          onClick={() => {
                            setIsActive('viewmeeting');
                            setIsOpen((prevState) => ({
                              ...prevState,
                              isHamburgerClicked: false,
                            }));
                          }}
                        >
                          Completed Meeting list
                        </Link>
                      </li>
                      <li
                        className={`${
                          isActive == 'meeting' ||
                          isActive.includes('viewmeeting')
                            ? 'active'
                            : null
                        }`}
                      >
                        <Link
                          to="/meet-slot-time"
                          title="Meeting Slot Time"
                          reloadDocument
                          onClick={() => {
                            setIsActive('viewSlotTime');
                            setIsOpen((prevState) => ({
                              ...prevState,
                              isHamburgerClicked: false,
                            }));
                          }}
                        >
                          Slot Time
                        </Link>
                      </li>
                    </ul>
                  </Collapse>
                ) : null}
                {isUserDoctor() ? (
                  <Collapse
                    in={isOpen.meetingTab && isActive.includes('meeting')}
                  >
                    <ul
                      className="sidenav_second_level"
                      id="collapseComponents"
                    >
                      <li
                        className={`${
                          isActive == 'meeting' ||
                          isActive.includes('viewmeeting')
                            ? 'active'
                            : null
                        }`}
                      >
                        <Link
                          to="/orders-meeting-list"
                          title="Orders Meeting List"
                          reloadDocument
                          onClick={() => {
                            setIsActive('viewmeeting');
                            setIsOpen((prevState) => ({
                              ...prevState,
                              isHamburgerClicked: false,
                            }));
                          }}
                        >
                          Meeting list
                        </Link>
                      </li>
                      <li
                        className={`${
                          isActive == 'meeting' ||
                          isActive.includes('viewmeeting')
                            ? 'active'
                            : null
                        }`}
                      >
                        <Link
                          to="/old-orders-meeting-list"
                          title="Completed Orders Meeting List"
                          onClick={() => {
                            setIsActive('viewmeeting');
                            setIsOpen((prevState) => ({
                              ...prevState,
                              isHamburgerClicked: false,
                            }));
                          }}
                        >
                          Completed Meeting list
                        </Link>
                      </li>
                    </ul>
                  </Collapse>
                ) : null}
              </li>
              {/* Gp Letters Management */}
              {isUserAdmin() ? (
                <li className="custom_nav_item">
                  <Link
                    className={`nav-link custom_nav_link nav-link-collapse custom_nav_link_collapse ${
                      isOpen.gpLetters ? null : 'collapsed'
                    } ${
                      isActive.includes('gp') && isOpen.gpLetters
                        ? 'active'
                        : null
                    }`}
                    title="GP Letters"
                    to="#"
                    onClick={() => {
                      setIsOpen((prevState) => ({
                        ...prevState,
                        productTab: false,
                        promotionTab: false,
                        orderTab: false,
                        userTab: false,
                        settingTab: false,
                        stockTab: false,
                        gpLetters: !prevState.gpLetters,
                        meetingTab: false,
                      }));
                      setIsActive('gp-letters');
                    }}
                  >
                    <i className="fa fa-user-o fa-fw"></i>
                    <span className="nav_link_text">GP Letters</span>
                  </Link>
                  <Collapse in={isOpen.gpLetters}>
                    <ul
                      className="sidenav_second_level"
                      id="collapseComponents"
                    >
                      <li
                        className={`${
                          isActive == 'gp-letters' ? 'active' : null
                        }`}
                      >
                        <Link
                          to="/gp-letters"
                          title="Send GP Letter"
                          reloadDocument
                          onClick={() => {
                            setIsActive('gp-letters');
                            setIsOpen((prevState) => ({
                              ...prevState,
                              isHamburgerClicked: false,
                            }));
                          }}
                        >
                          Send GP Letters
                        </Link>
                      </li>
                      <li
                        className={`${
                          isActive == 'gp-letters-download' ? 'active' : null
                        }`}
                      >
                        <Link
                          to="/gp-letters-download"
                          title="Download GP Letter"
                          reloadDocument
                          onClick={() => {
                            setIsActive('gp-letters-download');
                            setIsOpen((prevState) => ({
                              ...prevState,
                              isHamburgerClicked: false,
                            }));
                          }}
                        >
                          Download GP Letters
                        </Link>
                      </li>
                    </ul>
                  </Collapse>
                </li>
              ) : null}
              {/* Banner management */}
              {isUserAdmin() ? (
                <li className="custom_nav_item">
                  <Link
                    className={`nav-link custom_nav_link ${
                      isActive == 'banner' ? 'active' : null
                    }`}
                    to="banner"
                    title="Banner Management"
                    onClick={() => {
                      setIsOpen((prevState) => ({
                        ...prevState,
                        productTab: false,
                        promotionTab: false,
                        orderTab: false,
                        stockTab: false,
                        userTab: false,
                        settingTab: false,
                        isHamburgerClicked: false,
                        meetingTab: false,
                      }));
                      setIsActive('banner');
                    }}
                  >
                    <i className="fa fa-picture-o fa-fw"></i>
                    <span className="nav_link_text">Banner Management</span>
                  </Link>
                </li>
              ) : null}

              {/* Blog Management */}
              {isUserAdmin() ? (
                <li className="custom_nav_item">
                  <Link
                    className={`nav-link custom_nav_link ${
                      isActive == 'blog' ? 'active' : null
                    }`}
                    to="/blog"
                    title="Blogs Management"
                    onClick={() => {
                      setIsOpen((prevState) => ({
                        ...prevState,
                        productTab: false,
                        promotionTab: false,
                        orderTab: false,
                        stockTab: false,
                        userTab: false,
                        settingTab: false,
                        meetingTab: false,
                        isHamburgerClicked: false,
                      }));
                      setIsActive('blog');
                    }}
                  >
                    <i className="fa fa-pencil-square-o fa-fw"></i>
                    <span className="nav_link_text">Blogs Management</span>
                  </Link>
                </li>
              ) : null}

              {/* User management */}
              {isUserAdmin() ? (
                <li className="custom_nav_item">
                  <Link
                    className={`nav-link custom_nav_link nav-link-collapse custom_nav_link_collapse ${
                      isOpen.userTab ? null : 'collapsed'
                    } ${
                      isActive.includes('user') && isOpen.userTab
                        ? 'active'
                        : null
                    }`}
                    title="User Management"
                    to="#"
                    onClick={() => {
                      setIsOpen((prevState) => ({
                        ...prevState,
                        productTab: false,
                        promotionTab: false,
                        orderTab: false,
                        userTab: !prevState.userTab,
                        settingTab: false,
                        stockTab: false,
                        meetingTab: false,
                      }));
                      setIsActive('user');
                    }}
                  >
                    <i className="fa fa-user-o fa-fw"></i>
                    <span className="nav_link_text">User Management</span>
                  </Link>
                  <Collapse in={isOpen.userTab}>
                    <ul
                      className="sidenav_second_level"
                      id="collapseComponents"
                    >
                      <li
                        className={`${
                          isActive == 'admin-doctor' || isActive == 'user'
                            ? 'active'
                            : null
                        }`}
                      >
                        <Link
                          to="/admin-doctor"
                          title="Admin/Doctor/Pharmacist"
                          reloadDocument
                          onClick={() => {
                            setIsActive('admin-doctor');
                            setIsOpen((prevState) => ({
                              ...prevState,
                              isHamburgerClicked: false,
                            }));
                          }}
                        >
                          Admin/Doctor/Pharmacist
                        </Link>
                      </li>
                      <li
                        className={`${isActive == 'users' ? 'active' : null}`}
                      >
                        <Link
                          to="/users"
                          title="Users"
                          reloadDocument
                          onClick={() => {
                            setIsActive('users');
                            setIsOpen((prevState) => ({
                              ...prevState,
                              isHamburgerClicked: false,
                            }));
                          }}
                        >
                          Users
                        </Link>
                      </li>
                      <li
                        className={`${
                          isActive == 'duplicate-user' ? 'active' : null
                        }`}
                      >
                        <Link
                          to="/duplicate-users"
                          title="Duplicate Users"
                          reloadDocument
                          onClick={() => {
                            setIsActive('duplicate-user');
                            setIsOpen((prevState) => ({
                              ...prevState,
                              isHamburgerClicked: false,
                            }));
                          }}
                        >
                          Duplicate Users ({totalTotalDuplicateUsers})
                        </Link>
                      </li>
                      <li
                        className={`${
                          isActive == 'general-health-questions'
                            ? 'active'
                            : null
                        }`}
                      >
                        <Link
                          to="/gp-address"
                          title="GP Address"
                          onClick={() => {
                            setIsActive('gp-address');
                            setIsOpen((prevState) => ({
                              ...prevState,
                              isHamburgerClicked: false,
                            }));
                          }}
                        >
                          GP Address
                        </Link>
                      </li>
                    </ul>
                  </Collapse>
                </li>
              ) : null}

              {/* Stock Control */}
              {isUserAdmin() ? (
                <li className="custom_nav_item">
                  <Link
                    className={`nav-link custom_nav_link nav-link-collapse custom_nav_link_collapse ${
                      isOpen.stockTab ? null : 'collapsed'
                    } ${
                      isActive.includes('stock') && isOpen.stockTab
                        ? 'active'
                        : null
                    }`}
                    title="Stock Control"
                    to="#"
                    onClick={() => {
                      setIsOpen((prevState) => ({
                        ...prevState,
                        stockTab: !prevState.stockTab,
                        userTab: false,
                        orderTab: false,
                        productTab: false,
                        promotionTab: false,
                        settingTab: false,
                        meetingTab: false,
                      }));
                      setIsActive('stock');
                    }}
                  >
                    <i className="fa fa-sliders fa-fw "></i>
                    <span className="nav_link_text">Stock Control</span>
                  </Link>
                  <Collapse in={isOpen.stockTab}>
                    <ul
                      className="sidenav_second_level"
                      id="collapseComponents3"
                    >
                      <li
                        className={`${
                          isActive == 'stock-bulk' ? 'active' : null
                        }`}
                      >
                        <Link
                          to="stock-upload"
                          title="Bulk Excel Upload"
                          reloadDocument
                          onClick={() => {
                            setIsActive('stock-bulk');
                            setIsOpen((prevState) => ({
                              ...prevState,
                              isHamburgerClicked: false,
                            }));
                          }}
                        >
                          Bulk Excel Upload
                        </Link>
                      </li>
                      <li
                        className={`${
                          isActive == 'stock-scan' ? 'active' : null
                        }`}
                      >
                        <Link
                          to="stock-scan"
                          title="Scan Stock Input"
                          reloadDocument
                          onClick={() => {
                            setIsActive('stock-scan');
                            setIsOpen((prevState) => ({
                              ...prevState,
                              isHamburgerClicked: false,
                            }));
                          }}
                        >
                          Scan stock input
                        </Link>
                      </li>
                    </ul>
                  </Collapse>
                </li>
              ) : null}

              {/* Reporting Section */}
              {isUserAdmin() ? (
                <li className="custom_nav_item">
                  <Link
                    className={`nav-link custom_nav_link nav-link-collapse custom_nav_link_collapse ${
                      isOpen.reportingTab ? null : 'collapsed'
                    } ${
                      isActive.includes('reporting' || 'accounting') &&
                      isOpen.reportingTab
                        ? 'active'
                        : null
                    }`}
                    title="Stock Control"
                    to="#"
                    onClick={() => {
                      setIsOpen((prevState) => ({
                        ...prevState,
                        stockTab: false,
                        reportingTab: !prevState.reportingTab,
                        userTab: false,
                        orderTab: false,
                        productTab: false,
                        promotionTab: false,
                        settingTab: false,
                        meetingTab: false,
                      }));
                      setIsActive('reporting');
                    }}
                  >
                    <i className="fa fa-sliders fa-fw "></i>
                    <span className="nav_link_text">Reporting Section</span>
                  </Link>
                  <Collapse in={isOpen.reportingTab}>
                    <ul
                      className="sidenav_second_level"
                      id="collapseComponents3"
                    >
                      <li
                        className={`${
                          isActive == 'pharmacist-reporting' ? 'active' : null
                        }`}
                      >
                        <Link
                          to="pharmacist-reporting"
                          title="Pharmacist Reporting"
                          reloadDocument
                          onClick={() => {
                            setIsActive('pharmacist-reporting');
                            setIsOpen((prevState) => ({
                              ...prevState,
                              isHamburgerClicked: false,
                            }));
                          }}
                        >
                          Pharmacist Reporting
                        </Link>
                      </li>
                      <li
                        className={`${
                          isActive == 'accounting' ? 'active' : null
                        }`}
                      >
                        <Link
                          to="accounting"
                          title="Accounting"
                          reloadDocument
                          onClick={() => {
                            setIsActive('accounting');
                            setIsOpen((prevState) => ({
                              ...prevState,
                              isHamburgerClicked: false,
                            }));
                          }}
                        >
                          Accounting
                        </Link>
                      </li>
                      <li
                        className={`${
                          isActive == 'doctor-reporting' ? 'active' : null
                        }`}
                      >
                        <Link
                          to="doctor-reporting"
                          title="Doctor Reporting"
                          reloadDocument
                          onClick={() => {
                            setIsActive('doctor-reporting');
                            setIsOpen((prevState) => ({
                              ...prevState,
                              isHamburgerClicked: false,
                            }));
                          }}
                        >
                          Doctor Reporting
                        </Link>
                      </li>
                    </ul>
                  </Collapse>
                </li>
              ) : null}
              {/* Contact Us */}
              {isUserAdmin() ? (
                <li className="custom_nav_item">
                  <Link
                    className={`nav-link custom_nav_link ${
                      isActive == 'contact' ? 'active' : null
                    }`}
                    to="contact"
                    title="Contact Us"
                    onClick={() => {
                      setIsOpen((prevState) => ({
                        ...prevState,
                        orderTab: false,
                        stockTab: false,
                        productTab: false,
                        promotionTab: false,
                        userTab: false,
                        settingTab: false,
                        isHamburgerClicked: false,
                        meetingTab: false,
                      }));
                      setIsActive('contact');
                    }}
                  >
                    <i className="fa fa-fw fa-phone"></i>
                    <span className="nav_link_text">Contact Us</span>
                  </Link>
                </li>
              ) : null}

              {/* SEO */}
              {isUserAdmin() && (
                <li className="custom_nav_item">
                  <Link
                    className={`nav-link custom_nav_link ${
                      isActive == 'SEO' ? 'active' : null
                    }`}
                    to="/seo"
                    reloadDocument
                    title="SEO"
                    onClick={() => {
                      setIsOpen((prevState) => ({
                        ...prevState,
                        productTab: false,
                        promotionTab: false,
                        orderTab: false,
                        stockTab: false,
                        userTab: false,
                        isHamburgerClicked: false,
                        settingTab: false,
                        meetingTab: false,
                      }));
                      setIsActive('SEO');
                    }}
                  >
                    <i className="fa fa-fw fa-dashboard"></i>
                    <span className="nav_link_text">SEO</span>
                  </Link>
                </li>
              )}

              {/* Settings */}
              <li className="custom_nav_item">
                <Link
                  className={`nav-link custom_nav_link nav-link-collapse custom_nav_link_collapse ${
                    isOpen.settingTab ? null : 'collapsed'
                  } ${
                    isActive.includes('setting') && isOpen.settingTab
                      ? 'active'
                      : null
                  }`}
                  title="Setting"
                  to="#"
                  onClick={() => {
                    setIsOpen((prevState) => ({
                      ...prevState,
                      productTab: false,
                      promotionTab: false,
                      orderTab: false,
                      userTab: false,
                      settingTab: !prevState.settingTab,
                      stockTab: false,
                      meetingTab: false,
                    }));
                    setIsActive('setting');
                  }}
                >
                  <i className="fa fa-cog fa-fw"></i>
                  <span className="nav_link_text">Settings</span>
                </Link>
                <Collapse in={isOpen.settingTab}>
                  <ul className="sidenav_second_level" id="collapseComponents">
                    <li
                      className={`${
                        isActive == 'user-profile' ? 'active' : null
                      }`}
                    >
                      <Link
                        to="/user-profile"
                        title="User Profile"
                        reloadDocument
                        onClick={() => {
                          setIsActive('user-profile');
                          setIsOpen((prevState) => ({
                            ...prevState,
                            isHamburgerClicked: false,
                          }));
                        }}
                      >
                        User Profile
                      </Link>
                    </li>
                  </ul>
                </Collapse>
              </li>
            </ul>
            <ul className="navbar-nav custom_nav sidenav_toggler">
              <li className="custom_nav_item">
                <div
                  className="nav-link custom_nav_link"
                  title="Arrow"
                  id="sidenavToggler"
                  onClick={() => {
                    props.handleSidenavToggle();
                  }}
                >
                  <img
                    src={indentDecrease}
                    width="30"
                    height="30"
                    alt="indent"
                  />
                </div>
              </li>
            </ul>

            <ul className="navbar-nav custom_nav ms-auto">
              <li className="d-flex px-3 px-lg-1 px-xl-2 align-items-center">
                {/* <div
                  className="me-2 search_click"
                  title="Search"
                  onClick={() => {
                    setIsOpen((prevState) => ({
                      ...prevState,
                      searchOption: !prevState.searchOption,
                    }));
                  }}
                >
                  {' '}
                  <img src={searchIcon} width="24" height="24" alt="" />{' '}
                </div> */}
                <input
                  className={`form-control shadow-none search_input  py-2 ${
                    isOpen.searchOption ? 'show' : null
                  }`}
                  type="text"
                  placeholder="Search"
                />
                <span className="input-group-append d-block d-lg-none ">
                  <button className="btn btn-primary search_btn" type="button">
                    <i className="fa fa-search"></i>
                  </button>
                </span>
              </li>
              {/* <li className="align-items-center d-flex px-3 px-lg-1 px-xl-2">
                <div className="nav-link custom_nav_link  me-lg-2">
                  <span className="d-lg-none">
                    <i className="fa fa-fw fa-bell me-3"></i> Alerts
                    <span className="badge badge-pill badge-warning">
                      6 New
                    </span>
                  </span>
                  <span className="indicator text-warning d-none d-lg-block">
                    <img src={bellIcon} width="24" height="24" alt="" />
                  </span>
                </div>
              </li> */}
              <li className="custom_nav_item dropdown px-3 px-lg-0">
                <div
                  className={`nav-link custom_nav_link dropdown-toggle d-flex align-items-center ${
                    isOpen.userDropdown ? 'show' : null
                  }`}
                  id="alertsDropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={() => {
                    setIsOpen((prevState) => ({
                      ...prevState,
                      userDropdown: !prevState.userDropdown,
                    }));
                  }}
                >
                  <img
                    // src={userphoto}
                    src={userImg}
                    className="d-inline-block img-fluid"
                    height="48"
                    width="48"
                    alt="user"
                    crossOrigin="true"
                  />
                  <p className="user_text me-2 ms-2">{fullname}</p>
                </div>
                <div
                  className={`dropdown-menu shadow border-0 custom_dropdown_menu ${
                    isOpen.userDropdown ? 'show' : null
                  }`}
                  aria-labelledby="alertsDropdown"
                >
                  <div
                    className="dropdown-item custom_dropdown_text"
                    onClick={() => {
                      setIsOpen((prevState) => ({
                        ...prevState,
                        userDropdown: !prevState.userDropdown,
                      }));
                      navigate('/user-profile');
                    }}
                  >
                    <img
                      src={setttingsIcon}
                      height="24"
                      width="24"
                      className="me-2"
                      alt="Profile"
                    />
                    Profile settings
                  </div>
                  <div className="dropdown-divider"></div>
                  <div
                    className="dropdown-item custom_dropdown_text"
                    href="#"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    onClick={() => {
                      setIsOpen((prevState) => ({
                        ...prevState,
                        userDropdown: !prevState.userDropdown,
                      }));
                      logout();
                      toastSuccess('Logged out Successfully!!');
                      navigate('/login');
                      localStorage.removeItem('path');
                    }}
                  >
                    <img
                      src={logOutImg}
                      className="d-inline-block img-fluid"
                      alt="user"
                    />
                    Log out
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </Collapse>
      </nav>
      <h2 className="top_title">Welcome back, {firstName}</h2>
    </>
  );
};

export default Header;
